var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"invoice-add-wrapper"},[_c('b-row',{staticClass:"invoice-add"},[_c('b-col',{attrs:{"cols":"12","xl":"12","md":"12"}},[_c('validation-observer',{ref:"inventoryValidation"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-card',{staticClass:"invoice-preview-card",attrs:{"no-body":""}},[_c('b-card-body',{staticClass:"invoice-padding pb-0"},[_c('div',{staticClass:"d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"},[_c('div',[_c('div',{staticClass:"logo-wrapper"},[_c('b-img',{staticStyle:{"max-width":"55px"},attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}}),_c('h3',{staticClass:"text-primary invoice-logo ml-0"},[_vm._v(" "+_vm._s(_vm.$t('InteGreat'))+" ")])],1)]),_c('div',{staticClass:"invoice-number-date mt-md-0 mt-2"},[_c('div',{staticClass:"d-flex align-items-center justify-content-md-end mb-1"},[_c('h4',{staticClass:"invoice-title"},[_vm._v(" "+_vm._s(_vm.$t("HDRID"))+" : ")]),_c('b-input-group',{staticClass:"input-group-merge invoice-edit-input-group disabled"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"HashIcon"}})],1),_c('b-form-input',{attrs:{"id":"invoice-data-id","disabled":""},model:{value:(_vm.inventorydata.hdrId),callback:function ($$v) {_vm.$set(_vm.inventorydata, "hdrId", $$v)},expression:"inventorydata.hdrId"}})],1)],1)])])]),_c('hr',{staticClass:"invoice-spacing"}),_c('b-card-body',{staticClass:"invoice-padding pb-0"},[_c('div',{staticClass:"d-flex justify-content-between flex-column invoice-spacing mt-0"},[_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(_vm.$t('GTIN'))+" ")]),_c('b-card-text',{staticClass:"mb-25"},[_c('validation-provider',{attrs:{"name":"GTIN","rules":"length:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"invoice-data-id"},model:{value:(_vm.inventorydata.gtin),callback:function ($$v) {_vm.$set(_vm.inventorydata, "gtin", $$v)},expression:"inventorydata.gtin"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_vm._v("   ")],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(_vm.$t('SKU'))+" ")]),_c('b-card-text',{staticClass:"mb-0"},[_c('validation-provider',{attrs:{"name":"SKU","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"invoice-data-id"},model:{value:(_vm.inventorydata.SKU),callback:function ($$v) {_vm.$set(_vm.inventorydata, "SKU", $$v)},expression:"inventorydata.SKU"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_vm._v("   ")],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(_vm.$t('Qty'))+" ")]),_c('b-card-text',{staticClass:"mb-0"},[_c('b-form-input',{attrs:{"type":"text"},model:{value:(_vm.inventorydata.QTY),callback:function ($$v) {_vm.$set(_vm.inventorydata, "QTY", $$v)},expression:"inventorydata.QTY"}})],1),_vm._v("   ")],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(_vm.$t('Price'))+" ")]),_c('b-card-text',{staticClass:"mb-0"},[_c('validation-provider',{attrs:{"name":"Price","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"invoice-data-id"},model:{value:(_vm.inventorydata.nettoPrice),callback:function ($$v) {_vm.$set(_vm.inventorydata, "nettoPrice", $$v)},expression:"inventorydata.nettoPrice"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_vm._v("   ")],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card-text',{staticClass:"mb-0"},[_c('b-form-textarea',{staticClass:"mb-2 mb-lg-0",attrs:{"placeholder":_vm.$t('Description')},model:{value:(_vm.inventorydata.description),callback:function ($$v) {_vm.$set(_vm.inventorydata, "description", $$v)},expression:"inventorydata.description"}})],1),_vm._v("   ")],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card-text',{staticClass:"mb-0"},[_c('b-form-textarea',{staticClass:"mb-2 mb-lg-0",attrs:{"placeholder":_vm.$t('internText')},model:{value:(_vm.inventorydata.internText),callback:function ($$v) {_vm.$set(_vm.inventorydata, "internText", $$v)},expression:"inventorydata.internText"}})],1),_vm._v("   ")],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-select',{staticClass:"invoice-filter-select",attrs:{"dir":"ltr","options":_vm.shopOptions,"placeholder":_vm.$t('Shop')},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var label = ref.label;
return [_c('span',{staticClass:"text-truncate overflow-hidden"},[_vm._v(" "+_vm._s(label)+" ")])]}}]),model:{value:(_vm.inventorydata.shop),callback:function ($$v) {_vm.$set(_vm.inventorydata, "shop", $$v)},expression:"inventorydata.shop"}}),_vm._v("   ")],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"4"}},[_c('label',{staticClass:"d-inline"},[_vm._v(" "+_vm._s(_vm.$t('Update Y/N'))+" ")]),_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"updYN","value":"Y"},model:{value:(_vm.inventorydata.updYN),callback:function ($$v) {_vm.$set(_vm.inventorydata, "updYN", $$v)},expression:"inventorydata.updYN"}},[_vm._v(" "+_vm._s(_vm.$t('Yes'))+" ")]),_c('b-form-radio',{attrs:{"name":"updYN","value":"N"},model:{value:(_vm.inventorydata.updYN),callback:function ($$v) {_vm.$set(_vm.inventorydata, "updYN", $$v)},expression:"inventorydata.updYN"}},[_vm._v(" "+_vm._s(_vm.$t('No'))+" ")])],1),_vm._v("   ")])],1)],1)],1)],1),_c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"outline-primary","block":""},on:{"click":function($event){return _vm.editInventory()}}},[_vm._v(" "+_vm._s(_vm.$t('Save'))+" ")])],1)],1)],1)])])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }